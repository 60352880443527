window.googletag = window.googletag || { cmd: [] };

googletag.cmd.push(() => {
  // Size Mapping
  const sizeMappingSetting = window.adsJSON.sizeMappings;
  Object.keys(sizeMappingSetting).forEach((sizeName) => {
    const sizeData = sizeMappingSetting[sizeName];

    const googletagsize = googletag.sizeMapping();
    Object.keys(sizeData).forEach((breakpoint) => {
      const size = sizeData[breakpoint];
      // eslint-disable-next-line no-eval
      googletagsize.addSize(JSON.parse(breakpoint), eval(size));
    }); // end foreachsize dat

    window[sizeName] = googletagsize.build();
  }); // end foreach size map
});

// Grab the appropriate prebid map (determined by BE based off of the vertical)
window.htPrebidMap = window.adsJSON.prebidMap;
if (window.htPrebidMap) {
  loadHTVariables();
} else {
  window.addEventListener("prebidMapLoaded", loadHTVariables);
}

function loadHTVariables() {
  const htVars = {
    adUnitSlots: {
      adUnitSlotsDesktopPostRegular: {},
    },
    prebidAdUnits: {
      prebidAdUnitsDesktopPostRegular: {},
    },
    pageKeyValues: window.adsJSON.pageLevelKVTargeting,
  };

  // foreach window.adsJSON
  window.adsJSON.adUnits.forEach((slot) => {
    // build basic slot info
    htVars.adUnitSlots.adUnitSlotsDesktopPostRegular[slot.divId] = {
      unitPath: slot.adCode,
      sizes: slot.sizes,
      htmlId: slot.divId,
      platform: slot.platform,
      pageType: "ros",
      sizeMapping: slot.sizeMapping,
      keyValues: slot.kvTargeting,
    };

    const noPrebidorTAM = [
      "RightRail_Vendor",
      "Homepage_Billboard",
      "Billboard",
      "WhatsNew_Billboard",
    ];

    if (noPrebidorTAM.includes(slot.name)) {
      htVars.adUnitSlots.adUnitSlotsDesktopPostRegular[slot.divId].options = {
        requestBid: slot.requestBid ?? false,
        amazonBid: slot.amazonBid ?? false,
        no_refresh: slot.noRefresh ?? false,
        collapseEmpty: slot.collapseEmpty ?? false,
      };
    } else {
      htVars.adUnitSlots.adUnitSlotsDesktopPostRegular[slot.divId].options = {
        adBlockRender: slot.adBlockRender ?? false,
      };
    }
    if (
      slot.controlledBy === "browsi" &&
      window.browsi &&
      (window.browsiManaged === undefined || window.browsiManaged === 1)
    ) {
      htVars.adUnitSlots.adUnitSlotsDesktopPostRegular[
        slot.divId
      ].options.controlledBy = slot.controlledBy;
      htVars.adUnitSlots.adUnitSlotsDesktopPostRegular[
        slot.divId
      ].keyValues.AmazonTAM = "HT";
      htVars.adUnitSlots.adUnitSlotsDesktopPostRegular[
        slot.divId
      ].keyValues.PrebidTech = "BR";
    }

    // Initailize variable if not loaded
    if (!window.htPrebidMap) {
      window.htPrebidMap = window.adsJSON.prebidMap;
    }

    if (!window.htPrebidMap[slot.name]) {
      return;
    }

    const appnexusAdUnits = [
      "Desktop_Adhesion",
      "Homepage_Top_Mobile",
      "In-Thread_Middle",
      "In-Thread_Lower_Mobile",
      "Mobile_Adhesion",
      "RightRail_Middle_Adhesion",
      "RightRail_Bottom_Adhesion",
      "RightRail_Top",
      "In-Thread_Middle_Mobile",
      "In-Thread_Lower",
    ];

    htVars.prebidAdUnits.prebidAdUnitsDesktopPostRegular[slot.adCode] =
      window.htPrebidMap[slot.name];
    if (
      window.adsJSON.prebidOverrides &&
      window.adsJSON.prebidOverrides.appnexus &&
      appnexusAdUnits.includes(slot.name)
    ) {
      const appnexus = htVars.prebidAdUnits.prebidAdUnitsDesktopPostRegular[
        slot.adCode
      ].bids.find((el) => el.bidder === "appnexus");
      if (appnexus) {
        appnexus.params.placementId = window.adsJSON.prebidOverrides.appnexus;
      }
    }
    const adagio = htVars.prebidAdUnits.prebidAdUnitsDesktopPostRegular[
      slot.adCode
    ].bids.find((el) => el.bidder === "adagio");
    if (adagio) {
      adagio.params.site = window.adConfig.adagioSite
        ? window.adConfig.adagioSite
        : window.location.hostname.replace("www.", "").replace(/\./g, "-");
    }
  }); // end foreach window.adsJSON.adUnits slot
  window.htAdsJSON = htVars;
  window.dispatchEvent(new Event("htAdsDataLoaded"));
}
